<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-card title="Seting Top Banner Style:">
        <a-tabs type="card">
          <a-tab-pane key="0" tab="Picture">
            <a-button type="primary">Upload Picture</a-button>
            <div style="margin-bottom: 20px;margin-top: 20px;">
              Recommended size of picture: 1800 px * 520 px, supporting JPG and PNG formats.
            </div>

            <img style="width: 100%;height: 520px;" :src="getStyleData.bannerUrl" />
          </a-tab-pane>

          <a-tab-pane key="1" tab="Color">
            <div style="width: 100%;height: 300px;">
              <elColorPicker :value="getStyleData.bannerColor" @change="handlerColorPickerChange" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <a-card title="Set Buttion Style:" style="margin-top: 20px">
        <a-tabs type="card">
          <a-tab-pane key="1" tab="Color">
            <div style="width: 100%;height: 300px;">
              <elColorPicker :value="getStyleData.btnColor" @change="handlerColorPickerChange" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <a-card title="Set Curves Style:"  style="margin-top: 20px">
        <a-tabs type="card">
          <a-tab-pane key="1" tab="Color">
            <div style="width: 100%;height: 300px;">
              <elColorPicker :value="getStyleData.curvesColor" @change="handlerColorPickerChange" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-card>

      <a-card title="Set Ingredients&Terpenes Style:"  style="margin-top: 20px">
        <a-select v-model="getStyleData.curvesTemplate" placeholer="Select">
          <a-select-option :key="3">Style1</a-select-option>
          <a-select-option :key="1">Style2</a-select-option>
          <a-select-option :key="2">Style3</a-select-option>
        </a-select>
      </a-card>

      <a-button type="primary" style="margin-top: 20px;" @click="clickPrimary">
        Save Setings
      </a-button>
    </a-card>

  </page-header-wrapper>
</template>

<script>
import {pageStyle, listStyle, delStyle, getStyle, addStyle} from '@/api/cigarette/style'
import elColorPicker from 'el-color-picker'

export default {
  name: 'BrandStyle',
  components: {
    elColorPicker
  },
  mixins: [],
  data () {
    return {
      curvesTemplate: 1,
      getStyleData: {
        bannerUrl: "",
        bannerColor: "",
        btnColor: "",
        curvesTemplate: "",
        curvesColor: ""

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    detailsData() {
      getStyle().then(res => {
        this.getStyleData = res.data.data;
      })
    },
    handlerColorPickerChange(e) {
      console.log(e);
      this.color = e;
    },
    clickPrimary() {
      addStyle(this.getStyleData).then(res => {
        this.$message.success("success");
      })
    },
  }
}
</script>
